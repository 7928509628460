import React from "react";
import { Link } from "react-router-dom";

export const Feature = (props: { date: string }) => {
  return (
    <div
      className="uk-grid-collapse uk-child-width-expand@s uk-text-center uk-grid uk-grid-match"
      uk-grid="true"
    >
      <div>
        <div className="uk-first-column feature-1 uk-padding">
          <div className="uk-inline uk-width-xlarge uk-margin-medium-top uk-margin-medium-bottom">
            <h3 className="uk-h2 uk-margin-top uk-light">
              Check out our new routes!
            </h3>
            <p className="uk-margin-medium-bottom uk-light">
              Last updated:{" "}
              {props.date ? new Date(props.date).toLocaleDateString() : "-"} 🕐
            </p>
            <div className="uk-grid-medium uk-flex-middle uk-flex-inline uk-grid">
              <div className="uk-first-column">
                <Link
                  className="uk-button uk-button-default uk-light uk-button-large "
                  to="route/changelog"
                >
                  New Routes
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="feature-2 uk-padding uk-light">
          <div className="uk-inline uk-width-xlarge uk-margin-medium-top uk-margin-medium-bottom">
            <h3 className="uk-h2 uk-margin-top uk-light">
              Get ready for Wild Wednesdays
            </h3>
            <p className="uk-margin-medium-bottom uk-light">
              Join us for our most interesting event 😉
            </p>
            <div className="uk-grid-medium uk-flex-middle uk-flex-inline uk-grid">
              <div className="uk-first-column">
                <Link
                  style={{ opacity: "0.5" }}
                  className="uk-button uk-button-default uk-light uk-button-large uk-disabled"
                  to=""
                >
                  Upcomming Events
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
