import React, { useEffect, useState } from "react";
import Map, { Source, Layer, Marker, FullscreenControl } from "react-map-gl";

import { Threebox } from "threebox-plugin";
import "maplibre-gl/dist/maplibre-gl.css";
import "threebox-plugin/dist/threebox.css";

import { API_URL, CARRIER_ID } from "../../config";
import Skeleton from "react-loading-skeleton";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZWR3aW5icm93d24iLCJhIjoiY2trcWs0YWZjMDg0bzJ1cXQyODB1NnNydSJ9.tlLwEw7sB5aNaHxQTpqkRw";

const skyLayer = {
  id: "sky",
  type: "sky",
  paint: {
    "sky-type": "atmosphere",
    "sky-atmosphere-sun": [210, 10],
    "sky-atmosphere-sun-intensity": 5,
  },
};
const layerStyle = {
  id: "point",
  type: "circle",
  paint: {
    "circle-radius": 10,
    "circle-color": "#007cbf",
  },
};

const paintLayer = {
  id: "add-3d-buildings",
  source: "composite",
  "source-layer": "building",
  filter: ["==", "extrude", "true"],
  type: "fill-extrusion",
  minzoom: 15,
  paint: {
    "fill-extrusion-color": "#aaa",

    // Use an 'interpolate' expression to
    // add a smooth transition effect to
    // the buildings as the user zooms in.
    "fill-extrusion-height": [
      "interpolate",
      ["linear"],
      ["zoom"],
      15,
      0,
      15.05,
      ["get", "height"],
    ],
    "fill-extrusion-base": [
      "interpolate",
      ["linear"],
      ["zoom"],
      15,
      0,
      15.05,
      ["get", "min_height"],
    ],
    "fill-extrusion-opacity": 0.8,
  },
};
export const LivemapFull = () => {
  const [markers, setMarkers] = useState([]);
  const [mapWidth, setMapWidth] = useState("100%");
  const [mapHeight, setMapHeight] = useState("800px");
  const [showSidebar, setShowSidebar] = useState(false);
  const [flight, setFlight] = useState(null);
  // All flight information
  const [flightInfo, setFlightInfo] = useState({});

  // New Live map
  const [activeFlights, setActiveFlights] = useState([]);

  // Airport Data
  const [airports, setAirports] = useState([]);

  const getActiveFlights = async () => {
    // const req = await fetch(`https://sa.rotate.group/position/${CARRIER_ID}`, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    const req = await fetch("https://kv.rotate.group", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        command: "GET",
        key: `live-${CARRIER_ID}-*`,
      }),
    });

    const res = await req.json();
    setActiveFlights(res);
  };

  const getAirports = async () => {
    const req = await fetch(`${API_URL}airports?carrierId=${CARRIER_ID}`);

    const res = await req.json();

    if (req.status === 200) {
      setAirports(res.data);
    }
  };

  useEffect(() => {
    const getActiveFlightsData = setInterval(() => getActiveFlights(), 250);

    // const getData = async () => {};
    // const getMapData = setInterval(() => getData(), 250);
    if (airports.length === 0) {
      getAirports();
    }
    return () => {
      clearInterval(getActiveFlightsData);
      //clearInterval(getMapData);
    };
  }, [activeFlights]);

  useEffect(() => {
    const newMarkers = [];
    activeFlights.map((data) => {
      //if (data.length !== 2) return;
      const markerData = JSON.parse(data.data);
      console.log(markerData);
      newMarkers.push({
        markerData: {
          position_report: markerData,
          id: markerData.flightId,
        },
      });
    }),
      activeFlights.map(async (data) => {
        const markerData = JSON.parse(data.data);
        const flightId = markerData.flightId;
        // get flight data if it doesn't exist
        if (flightId in flightInfo === false) {
          const req = await fetch(`${API_URL}flight/${flightId}`);
          const res = await req.json();
          setFlightInfo((prev) => ({
            ...prev,
            [flightId]: res.data,
          }));
        }
      });
    setMarkers(newMarkers);
  }, [activeFlights]);

  return (
    <>
      <Map
        initialViewState={{
          longitude: -97,
          latitude: 37,
          zoom: 3.5,
        }}
        style={{ width: mapWidth, height: mapHeight }}
        mapboxAccessToken={MAPBOX_TOKEN}
        maxPitch={85}
        mapStyle="mapbox://styles/edwinbrowwn/ckkqk5zot1c8617o9eboaez4u" //mapbox://styles/edwinbrowwn/cl3eoxc55000v14lov160x3zz"
        terrain={{ source: "mapbox-dem", exaggeration: 1.5 }}
      >
        {showSidebar && flight.id in flightInfo && (
          <div
            className="sidebar uk-position-absolute"
            style={{
              top: 0,
              right: 0,
              left: 0,
              zIndex: 999,
              pointerEvents: "none",
              height: "100%",
            }}
          >
            <div className="uk-padding-large">
              <div className="uk-grid" uk-grid="true">
                {flight ? (
                  <div
                    className="uk-child-width-1-2@m"
                    uk-grid="true"
                    style={{ pointerEvents: "all" }}
                  >
                    <div>
                      <div
                        className="sidebar__header uk-text-center"
                        style={{
                          width: "400px",
                          maxWidth: "calc(100vw - 30px)",
                          margin: "0 auto",
                        }}
                      >
                        <button
                          className="uk-button-primary uk-padding-small uk-margin-small-bottom uk-cursor-pointer"
                          onClick={() => setShowSidebar(false)}
                        >
                          Hide Sidebar
                        </button>
                      </div>
                      <div
                        className="uk-card uk-card-default"
                        style={{ width: 400, maxWidth: "calc(100vw - 30px)" }}
                      >
                        <div
                          className="uk-card-media-top bg-image"
                          style={{
                            width: "100%",
                            height: 200,
                            backgroundPosition: "center, center, center",
                            background: ` url("../../images/types/${
                              flightInfo[flight.id].aircraft_type
                            }.jpg") center`,
                            backgroundSize: "cover",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: 200,
                              backgroundPosition: "center, center, center",
                              background: `linear-gradient(to top, #f0f1f3 5%, rgba(221, 221, 221, 0))`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>
                        <div
                          className="uk-card-body uk-text-left uk-padding-remove-top"
                          style={{ overflowY: "auto", maxHeight: "45vh" }}
                        >
                          <div
                            className="uk-grid-small uk-child-width-1-2"
                            uk-grid="true"
                          >
                            <div>
                              <h2 className="uk-card-title uk-heading-large ">
                                {flightInfo[flight.id].schedule.callsign}
                                {flightInfo[flight.id].schedule.flight_number}
                              </h2>
                            </div>
                            <div>
                              <h5 className="uk-padding-remove-top uk-margin-remove-top">
                                User:<br></br>
                                {flightInfo[flight.id].user.username}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="uk-grid-small uk-child-width-1-2"
                            uk-grid="true"
                          >
                            <div>
                              <h5 className="uk-padding-remove-top uk-margin-remove-top">
                                Aircraft Type:<br></br>
                                {flightInfo[flight.id].aircraft_type}
                              </h5>
                            </div>
                            <div>
                              <h5>
                                Current Altitude:<br></br>
                                {flight.position_report.altitude} ft
                              </h5>
                            </div>
                            <div>
                              <h5>
                                Status:<br></br>
                                {flight.position_report.status}
                              </h5>
                            </div>
                            <div>
                              <h5>
                                Notes:<br></br>
                                {flightInfo[flight.id].schedule.notes || "None"}
                              </h5>
                            </div>
                          </div>

                          <br></br>

                          <div>
                            <div>
                              <div className="uk-tile uk-tile-muted uk-padding-small">
                                <p className="uk-margin-remove-bottom uk-padding-remove-bottom">
                                  {flightInfo[flight.id].schedule.dep_icao} -{" "}
                                  {
                                    airports.find(
                                      (a) =>
                                        a.icao ===
                                        flightInfo[flight.id].schedule.dep_icao
                                    ).name
                                  }
                                </p>
                                <p className="uk-text-italic uk-padding-remove-top uk-margin-remove-top">
                                  {new Date(
                                    flightInfo[flight.id].dep_time
                                  ).toLocaleTimeString(undefined, {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </p>
                              </div>
                            </div>
                          </div>
                          <br></br>
                          <div>
                            <div>
                              <div className="uk-tile uk-tile-muted uk-padding-small">
                                <p className="uk-margin-remove-bottom uk-padding-remove-bottom">
                                  {flightInfo[flight.id].schedule.arr_icao} -{" "}
                                  {
                                    airports.find(
                                      (a) =>
                                        a.icao ===
                                        flightInfo[flight.id].schedule.arr_icao
                                    ).name
                                  }
                                </p>
                                <p className="uk-text-italic uk-padding-remove-top uk-margin-remove-top">
                                  Estimated Arrival Time:{" "}
                                  {new Date(
                                    new Date(
                                      flightInfo[flight.id].dep_time
                                    ).getTime() +
                                      flightInfo[flight.id].schedule
                                        .est_flight_time *
                                        3600000
                                  ).toLocaleTimeString(undefined, {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* {flight.logItems ? (
                            <>
                              <br></br>
                              <div>
                                <div>
                                  <h5>Log:</h5>
                                  <div className="uk-tile uk-tile-muted uk-padding-small">
                                    <div style={{ "overflow-y": "auto" }}>
                                      {flight.logItems.map((item) => (
                                        <p className="uk-margin-small uk-text-small">
                                          <span className="uk-text-italic">
                                            {item[0]}:{" "}
                                          </span>
                                          {item[1]}
                                        </p>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        )}
        {showSidebar && !(flight.id in flightInfo) && (
          <div
            className="sidebar uk-position-absolute"
            style={{
              top: 0,
              right: 0,
              left: 0,
              zIndex: 999,
              pointerEvents: "none",
              height: "100%",
            }}
          >
            <div className="uk-padding-large">
              <div className="uk-grid" uk-grid="true">
                <div
                  className="uk-child-width-1-2@m"
                  uk-grid="true"
                  style={{ pointerEvents: "all" }}
                >
                  <div>
                    <div
                      className="sidebar__header uk-text-center"
                      style={{
                        width: "400px",
                        maxWidth: "calc(100vw - 30px)",
                        margin: "0 auto",
                      }}
                    >
                      <button
                        className="uk-button-primary uk-padding-small uk-margin-small-bottom uk-cursor-pointer"
                        onClick={() => setShowSidebar(false)}
                      >
                        Hide Sidebar
                      </button>
                    </div>
                    <div
                      className="uk-card uk-card-default"
                      style={{ width: 400, maxWidth: "calc(100vw - 30px)" }}
                    >
                      <div
                        className="uk-card-media-top bg-image"
                        style={{
                          width: "100%",
                          height: 200,
                          backgroundPosition: "center, center, center",
                          backgroundSize: "cover",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: 200,
                            backgroundPosition: "center, center, center",
                            background: `linear-gradient(to top, #f0f1f3 5%, rgba(221, 221, 221, 0))`,
                            backgroundSize: "cover",
                          }}
                        ></div>
                      </div>
                      <div
                        className="uk-card-body uk-text-left uk-padding-remove-top"
                        style={{ overflowY: "auto", maxHeight: "45vh" }}
                      >
                        <div
                          className="uk-grid-small uk-child-width-1-2"
                          uk-grid="true"
                        >
                          <div>
                            <h2 className="uk-card-title uk-heading-large uk-margin-small-top">
                              <Skeleton className="" />
                            </h2>
                          </div>
                          <div>
                            <h5 className="uk-padding-remove-top uk-margin-remove-top">
                              User:<br></br>
                              <Skeleton />
                            </h5>
                          </div>
                        </div>
                        <div
                          className="uk-grid-small uk-child-width-1-2"
                          uk-grid="true"
                        >
                          <div>
                            <h5 className="uk-padding-remove-top uk-margin-remove-top">
                              Aircraft Type:<br></br>
                              <Skeleton />
                            </h5>
                          </div>
                          <div>
                            <h5>
                              Current Altitude:<br></br>
                              <Skeleton />
                            </h5>
                          </div>
                          <div>
                            <h5>
                              Status:<br></br>
                              <Skeleton />
                            </h5>
                          </div>
                          <div>
                            <h5>
                              Notes:<br></br>
                              <Skeleton />
                            </h5>
                          </div>
                        </div>

                        <br></br>

                        <div>
                          <div>
                            <div className="uk-tile uk-tile-muted uk-padding-small">
                              <p className="uk-margin-remove-bottom uk-padding-remove-bottom">
                                <Skeleton />
                              </p>
                              <p className="uk-text-italic uk-padding-remove-top uk-margin-remove-top">
                                <Skeleton />
                              </p>
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div>
                          <div>
                            <div className="uk-tile uk-tile-muted uk-padding-small">
                              <p className="uk-margin-remove-bottom uk-padding-remove-bottom">
                                <Skeleton />
                              </p>
                              <p className="uk-text-italic uk-padding-remove-top uk-margin-remove-top">
                                Estimated Arrival Time: <Skeleton />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Source
          id="mapbox-dem"
          type="raster-dem"
          url="mapbox://mapbox.mapbox-terrain-dem-v1"
          tileSize={512}
          maxzoom={14}
        />
        <Layer {...skyLayer} />
        <Layer {...paintLayer} />

        {markers.length > 0 &&
          markers.map((m) => (
            <>
              <Marker
                className="map-marker"
                longitude={m.markerData.position_report.longitude}
                latitude={m.markerData.position_report.latitude}
                rotation={m.markerData.position_report.heading}
                //pitchAlignment={"viewport"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFlight(m.markerData);
                  setShowSidebar(true);
                }}
              >
                <img src="./images/map-icons/360.png" height={40} width={40} />
              </Marker>
            </>
          ))}
        <FullscreenControl />
      </Map>
    </>
  );
};
