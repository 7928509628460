import React, { useState, useContext, useEffect } from "react";
import { Navbar } from "../components/navbar/Navbar.jsx";
import { HeroSmall } from "../components/hero/HeroSmall";
import { Footer } from "../components/footer/Footer.jsx";
import { getCarrierInfo, getBidLine, deleteBidLIne, saveBidLine, generateBidLine } from "../middleware/core";

import { UserContext } from "../UserContext";
import { isAuthenticated, refresh } from "../middleware/auth";
import { getUserInfo } from "../middleware/core";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

import Multiselect from 'multiselect-react-dropdown';

const typeGroupings = [

  { name: 'Airbus 320/20N/321/21N', id: 'A320,A20N,A321,A21N', gid: 'Type Groupings' },
]

export const Pbs = () => {
  const [bidLine, setBidLine] = useState([]);
  const [generatedLine, setGeneratedLine] = useState();
  const [loading, setLoading] = useState(true);
  const [aircraftTypes, setAircraftTypes] = useState([]);
  const [operators, setOperators] = useState([]);

  const [depIcaoValue, setDepIcaoValue] = useState('');
  const [aircraftTypeValue, setAircraftTypeValue] = useState('');
  const [operatorValue, setOperatorValue] = useState('');
  const [legCountValue, setLegCountValue] = useState('');
  const [returnToDepValue, setReturnToDepValue] = useState(true);

  const [error, setError] = useState();
  const [alertText, setAlertText] = useState();

  const { userContextData, setUserContextData } = useContext(UserContext);
  useEffect(() => {
    const getInitialData = async () => {
      if (isAuthenticated()) {
        const user = await refresh();
        const userInfo = await getUserInfo(user.public_id);
        user.info = userInfo;
        setUserContextData(user);
        const bidLine = await getBidLine()
        setBidLine(bidLine)
        if (bidLine) setLoading(false);
        if (userInfo.last_location) setDepIcaoValue(userInfo.last_location)
        // Check simbrief for each bid
        const carrierSettings = await getCarrierInfo()
        setAircraftTypes(carrierSettings.settings.airframes)
        setOperators(carrierSettings.settings.schedules_operators)
        setOperatorValue(carrierSettings.settings.schedules_operators[0] || '')

        setLoading(false);
      }
    }

    getInitialData()
  }, []);

  const deleteBidLineEvent = async (e, id) => {
    e.preventDefault()
    const deletedLine = await deleteBidLIne(id);
    const removedBidsLength = deletedLine.deleteBids.filter(b => b.count === 1).length
    if (deletedLine) showSuccessAlert(`Bid line removed, ${removedBidsLength} associated bids deleted`)
    setTimeout(() => {
      setBidLine([])
    }, 1000)
  }

  const acceptBidLineEvent = async (e, map) => {
    e.preventDefault();
    const savedLine = await saveBidLine(map);
    if (savedLine.public_id) {
      showSuccessAlert('Accepted bid line')
      const newBidLine = await getBidLine()
      setGeneratedLine(null)
      setBidLine(newBidLine);
    }
  }

  const generateBidLineEvent = async (e) => {
    e.preventDefault();
    if (!depIcaoValue) {
      showErrorAlert('Please select a departure airport')
    }
    if (!aircraftTypeValue) {
      showErrorAlert('Please select an aircraft type')
      return
    }
    if (!legCountValue) {
      showErrorAlert('Please select a leg count')
      return
    }
    if (!operatorValue) {
      showErrorAlert('Please select an operator')
      return
    }
    const generatedBidLine = await generateBidLine({
      depIcao: depIcaoValue ?? null,
      aircraftType: aircraftTypeValue ?? null,
      legCount: Number(legCountValue) ?? null,
      operator: operatorValue ?? null,
      returnToDep: returnToDepValue ?? false,
    })
    if (generatedBidLine) {
      //showSuccessAlert('Bid line generated');
      setGeneratedLine(generatedBidLine)
    } else {
      showErrorAlert('Unable to generate line with provided parameters')
    }
  }

  const showSuccessAlert = (text) => {
    setError(false)
    setAlertText(text);
    setTimeout(() => {
      setAlertText(null);
    }, 5000);
  }

  const showErrorAlert = (text) => {
    setError(true)
    setAlertText(text);
    setTimeout(() => {
      setAlertText(null);
    }, 5000);
  }

  return (
    <>
      <div className="section-intro">
        <Navbar user={userContextData} />
        <HeroSmall title="Bidding System" subTitle="Crew Center" />
      </div>

      <div className="uk-section uk-padding-medium">
        <div className="uk-container">
          <>
            {alertText ? (

              <div className={error ? "uk-alert-danger" : "uk-alert-success"} uk-alert="true">
                <a className="uk-alert-close" uk-close="true" onClick={() => setAlertText(null)} />
                <p>{alertText}</p>
              </div>) : <></>}
            {!loading && (
              <form className="uk-grid-small uk-grid-match uk-grid uk-margin-medium-bottom" uk-grid="true" onSubmit={e => generateBidLineEvent(e)}>
                <div className="uk-grid-small uk-grid-match uk-grid  uk-width-1-1" uk-grid="true">

                  <div className="uk-width-1-6@s">
                    <input
                      className={`uk-input uk-input-large uk-height-1-1`} // ${!depIcaoValue ? 'uk-form-danger' : ''}
                      type="text"
                      placeholder="Departure ICAO"
                      value={depIcaoValue}
                      onChange={e => setDepIcaoValue(e.target.value.toUpperCase())}
                      required={true}
                    />
                  </div>

                  <div className="uk-width-1-3@s">
                    {/* <select title="Aircraft Types" className="uk-select uk-input uk-select-large uk-height-1-1" onChange={e => setAircraftTypeValue(e.target.value)}>
                    <option value={null} default>Aircraft Type</option>
                    {
                      aircraftTypes.length > 0 && aircraftTypes.map((type, index) => {
                        return (
                          <option key={index} value={type}>{type}</option>
                        )
                      })
                    }
                  </select> */}
                    {
                      aircraftTypes && (
                        <Multiselect
                          className="uk-select uk-input uk-select-large uk-height-1-1"

                          options={[...typeGroupings, ...aircraftTypes.map(t => ({ name: t, id: t, gid: 'Single Types' }))]} // Options to display in the dropdown
                          // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                          onSelect={(selectedList, selectedItem) => {
                            setAircraftTypeValue([...aircraftTypeValue.split(','), selectedItem.id].join(','))
                          }} // Function will trigger on select event
                          onRemove={(selectedList, removedItem) => {
                            setAircraftTypeValue(aircraftTypeValue.split(',').filter(t => !removedItem.id.includes(t)).join(','))
                          }} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                          placeholder="Aircraft Type"
                          groupBy="gid"
                        />
                      )
                    }

                  </div>

                  <div className="uk-width-1-6@s">
                    <select title="Leg count" className="uk-select uk-input uk-select-large uk-height-1-1" onChange={e => setLegCountValue(e.target.value)}>
                      <option value={null} default>Leg Count</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                      <option value={9}>9</option>
                      <option value={10}>10</option>
                    </select>
                  </div>


                  <div className="uk-width-1-3@s">
                    <select title="Operator" className="uk-select uk-input uk-select-large uk-height-1-1" onChange={e => setOperatorValue(e.target.value)}>
                      <option value={null} default>Operator</option>
                      {
                        operators.length > 0 && operators.map((type, index) => {
                          return (
                            <option key={index} value={type} selected={index === 0 ? true : undefined}>{type}</option>
                          )
                        })
                      }
                    </select>
                  </div>

                  <div className="uk-width-1-1 uk-text-left uk-margin-small-top">
                    <label><input checked={returnToDepValue} type="checkbox" name="return-to-dep-checkbox" className="uk-checkbox uk-input" onClick={e => {
                      setReturnToDepValue(e.target.checked)
                    }}></input>
                      &nbsp;Return to departure ICAO</label>
                  </div>

                  <div className="uk-width-auto@s">
                    <button
                      className="uk-button uk-button-primary uk-button-large"
                      type="submit"
                    >
                      Generate
                    </button>

                  </div>
                </div>
                <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">

                </div>
              </form>
            )}
          </>
          <div className="uk-grid-small" uk-grid="true">
            <>
              {!!generatedLine && (
                <div className="uk-width-1-1">
                  <div className="uk-card uk-card-default uk-padding-small">
                    <h3 className="uk-heading-line uk-text-left uk-padding-small uk-margin-remove-bottom"><span>Generated Line</span></h3>
                    <table className="uk-table uk-text-left uk-table-middle uk-table-divider uk-table-hover uk-table-responsive uk-margin-remove-top">
                      <thead>
                        <tr>
                          <th>Leg #</th>
                          <th>Flight Number</th>
                          <th>Equipment</th>
                          <th>EST Duration</th>
                          <th>Departure ICAO</th>
                          <th>Arrival ICAO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {generatedLine.sequence.map((b, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{b.flight_number}</td>
                            <td>{b.aircraft_type}</td>
                            <td>{b.est_flight_time.toFixed(1)}</td>
                            <td>{b.dep_icao}</td>
                            <td>{b.arr_icao}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div>
                      <button
                        className="uk-button uk-button-primary uk-button-large"
                        onClick={e => bidLine.length === 0 ? acceptBidLineEvent(e, generatedLine.map) : void 0}
                        disabled={bidLine.length > 0}
                      >
                        {bidLine.length === 0 ? 'Accept Generated Line' : 'Unable to add bid line while bid line is active'}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
            {bidLine.length > 0 ? (
              bidLine.map((bid) => (

                <div className="uk-width-1-1">
                  <div className="uk-card uk-card-default uk-padding-small">
                    {!!bid.bids[0] && (
                      <h3 className="uk-heading-line uk-text-left uk-padding-small uk-margin-remove-bottom"><span>Current Line - {bid.bids[0].schedule.callsign}{bid.public_id.split('-')[0].toUpperCase()}</span></h3>
                    )}
                    <table className="uk-table uk-text-left uk-table-middle uk-table-divider uk-table-hover uk-table-responsive uk-margin-remove-top">
                      <thead>
                        <tr>
                          <th>Leg #</th>
                          <th>Flight Number</th>
                          <th>Equipment</th>
                          <th>EST Duration</th>
                          <th>Departure ICAO</th>
                          <th>Arrival ICAO</th>
                          <th>Leg Completed</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bid.bids.map((b, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{b.schedule.flight_number}</td>
                            <td>{b.schedule.aircraft_type}</td>
                            <td>{b.schedule.est_flight_time.toFixed(1)}</td>
                            <td>{b.schedule.dep_icao}</td>
                            <td>{b.schedule.arr_icao}</td>
                            <td>{bid.flights.find(f => f.schedule_id === b.schedule.public_id) ? <span className="uk-label uk-label-success">Completed</span> : <span className="uk-label uk-label-danger">Incomplete</span>}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div>
                      <button
                        className="uk-button uk-button-danger uk-button-large"
                        onClick={e => deleteBidLineEvent(e, bid.public_id)}
                      >
                        Delete Bid Line w/ associated Bids
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>{!loading ?
                <div className="uk-width-1-1">

                  <div className="uk-alert-primary uk-padding" uk-alert="true">
                    <a className="uk-alert-close" uk-close="true" />
                    <p>No active bid lines</p>
                  </div>

                </div> : <></>}
              </>
            )}
            {loading ? (
              <>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
                <div className="uk-width-1-1">
                  <Skeleton height={145} />
                </div>
              </>
            ) : <></>}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
