import React from "react";
import { Link } from "react-router-dom";

export const FeatureDetail = () => {
  return (
    <div className="uk-section uk-section-default uk-section-large">
      <div className="uk-container">
        <div className="uk-grid-large uk-flex-middle uk-text-center uk-text-left@l uk-grid">
          <div className=" uk-margin-medium-bottom uk-width-1-3@l  uk-first-column">
            <div className="uk-inline uk-width-xlarge">
              <h3 className="uk-h6 tm-h6 uk-margin-remove">
                WILDCARS - Flight Tracking
              </h3>
              <h4 className="uk-h1 uk-margin-top">Introducing WILDCARS</h4>
              <p className="uk-text-large tm-text-large">
                WILDCARS is our in house custom built ACARS solution. Only
                avalible for vFrontier. Start tracking your Frontier flights
                with vFrontier today!
              </p>
              <div className="uk-grid-medium uk-flex-middle uk-flex-inline uk-grid">
                <div className="uk-first-column">
                  <a
                    className="uk-button uk-button-primary uk-button-large "
                    href="https://github.com/Rotate-Group/v-frontier-acars-release/releases/download/v1.3.3/WILDCARS-Setup-1.3.3.exe"
                  >
                    Download WILDCARS
                  </a>
                  <br></br>
                  <small>*FSUIPC Required</small>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-2-3@l uk-flex-last@l">
            <img
              src="./images/acars-preview.gif"
              className="box-shadow-large"
              alt="Wildcars acars application"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
