import React, { useState, useEffect } from "react";
import { sendContactForm } from "../middleware/core"

import logo from "../images/fft-icon.jpg";

export const Contact = (props) => {
  // let type = 'General'
  // const searchParams = new URLSearchParams(window.location.search);
  // console.log(searchParams.get('type'))
  // if (searchParams.get('type') == 'stats') {
  //   type = 'Stats';
  // }
  useEffect(() => {
    window.location.href = 'https://forms.office.com/r/qCnkZnHYD2'
  })
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [email, setEmail] = useState('');
  // const [reason, setReason] = useState('General');
  // const [message, setMessage] = useState('')

  // const [error, setError] = useState();
  // const [alertText, setAlertText] = useState();
  // const [typeQuery, setTypeQuery] = useState(type);

  // const submitForm = async () => {
  //   const req_reg = await sendContactForm(firstName, lastName, email, reason, message);
  //   setAlertText(req_reg.detail);
  //   if (req_reg.success) {
  //     setAlertText(alertText + '... Redirecting to previous page');
  //     setError(false);
  //     setTimeout(() => {
  //       props.history.goBack()
  //     }, 3000)
  //   }
  //   if (!req_reg.success) setError(true);

  // }
  return <></>

  // return (
  //   <>
  //     <div className="uk-section login-section uk-padding-medium">
  //       <div className="uk-container">
  //         <div className="uk-grid uk-flex-center" uk-grid="true">
  //           <div className="uk-flex-first uk-width-1-2@s uk-width-1-1 uk-text-left uk-first-column">
  //             <div className="uk-card uk-card-default uk-padding-large uk-text-center">
  //               <h1 className="sky">
  //                 <img
  //                   src={logo}
  //                   className="uk-border-circle crew-logo"
  //                   alt="vfrontier crew login icon"
  //                 ></img>
  //                 Contact Form
  //               </h1>
  //               <form
  //                 className="uk-grid-small uk-grid-match uk-grid"
  //                 uk-grid="true"
  //                 onSubmit={(e) => {
  //                   e.preventDefault();
  //                   submitForm();
  //                 }}>
  //                 <div className="uk-width-1-1">
  //                   <div className="uk-position-relative uk-margin-medium">
  //                     {alertText ? (
  //                       <div className={error ? "uk-alert-danger" : "uk-alert-success"} uk-alert="true">
  //                         <a className="uk-alert-close" uk-close="true" />
  //                         <p>{alertText}</p>
  //                       </div>)
  //                       :
  //                       <></>}
  //                   </div>
  //                 </div>
  //                 <div className="uk-width-1-2@s uk-margin-bottom-small">
  //                   <input
  //                     className="uk-input uk-input-large"
  //                     type="text"
  //                     placeholder="First Name"
  //                     onInput={(e) => setFirstName(e.currentTarget.value)}
  //                   />
  //                 </div>
  //                 <div className="uk-width-1-2@s uk-margin-bottom-small">
  //                   <input
  //                     className="uk-input uk-input-large"
  //                     type="text"
  //                     placeholder="Last Name"
  //                     onInput={(e) => setLastName(e.currentTarget.value)}
  //                   />
  //                 </div>
  //                 <div className="uk-width-1-1 uk-margin-bottom-small">
  //                   <input
  //                     className="uk-input uk-input-large"
  //                     type="email"
  //                     placeholder="Email"
  //                     onInput={(e) => setEmail(e.currentTarget.value)}
  //                   />
  //                 </div>
  //                 <div className="uk-width-1-1 uk-margin-bottom-small">
  //                   <select
  //                     onChange={(e) => setReason(e.currentTarget.value)}
  //                     className="uk-select" defaultValue={typeQuery}>
  //                     <option value="General">General</option>
  //                     <option value="Website">Website</option>
  //                     <option value="Acars">Acars</option>
  //                     <option value="Stats">Stats</option>
  //                   </select>
  //                 </div>
  //                 <div className="uk-width-1-1 uk-margin-bottom">
  //                   <textarea
  //                     className="uk-textarea"
  //                     rows="5"
  //                     placeholder="Message"
  //                     maxLength="500"
  //                     onInput={(e) => setMessage(e.currentTarget.value)}
  //                   ></textarea>
  //                 </div>
  //                 <div className="uk-text-left uk-margin-remove-top">
  //                   <a href="" onClick={(e) => {
  //                     e.preventDefault();
  //                     props.history.goBack()
  //                   }}>
  //                     Return to Site
  //                   </a>
  //                 </div>
  //                 <div className="uk-width-1-1 uk-width-1-1">
  //                   <button className="uk-button uk-button-primary uk-button-large">
  //                     Submit
  //                   </button>
  //                 </div>
  //               </form>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};
