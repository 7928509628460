import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import L from "leaflet";

export const HeroFlightsMap = ({
  title,
  subTitle,
  flights,
  airports,
  airportsMap,
}) => {
  const URL =
    "https://api.mapbox.com/styles/v1/edwinbrowwn/ckkqk5zot1c8617o9eboaez4u/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZWR3aW5icm93d24iLCJhIjoiY2trcWs0YWZjMDg0bzJ1cXQyODB1NnNydSJ9.tlLwEw7sB5aNaHxQTpqkRw";

  const [airportsArray, setAiports] = useState(null);
  const [sadAirports, setSadAiports] = useState(null);
  const [routes, setRoutes] = useState(null);

  const svgIcon = L.divIcon({
    html: `
          <svg
            width="50"
            height="50"
            viewBox="0 0 100 100"
            version="1.1"
            preserveAspectRatio="none"
            stroke="#1e87f0"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle r="5" cx="10" cy="10" fill="orange" />
          </svg>`,
    className: "",
    iconSize: [50, 50],
    iconAnchor: [5, 5],
  });

  const svgIcon2 = L.divIcon({
    html: `
          <svg
            width="50"
            height="50"
            viewBox="0 0 100 100"
            version="1.1"
            preserveAspectRatio="none"
            stroke="#1e87f0"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle r="5" cx="10" cy="10" fill="#fff" />
          </svg>`,
    className: "",
    iconSize: [50, 50],
    iconAnchor: [5, 5],
  });

  useEffect(() => {
    if (flights.length > 0 && airports.length > 0) {
      const airportsList = [];
      const newFLightsArray = [];
      const sadAirportsArray = [];

      flights.forEach((flight) => {
        flight = flight.schedule;
        if (!airportsList.find((a) => a.icao === flight.dep_icao))
          airportsList.push(airportsMap[flight.dep_icao]);
        if (!airportsList.find((a) => a.icao === flight.arr_icao))
          airportsList.push(airportsMap[flight.arr_icao]);

        flight.dep_lat = airportsMap[flight.dep_icao].latitude;
        flight.dep_lon = airportsMap[flight.dep_icao].longitude;

        flight.arr_lat = airportsMap[flight.arr_icao].latitude;
        flight.arr_lon = airportsMap[flight.arr_icao].longitude;
        newFLightsArray.push(flight);
      });

      setAiports(airportsList);
      setRoutes(newFLightsArray);

      // Get remaining airports
      airports.forEach((airport) => {
        if (!airportsList.includes(airport.icao)) {
          sadAirportsArray.push(airport);
        }
      });
      setSadAiports(sadAirportsArray);
    }
  }, [airports, flights, airportsMap]);

  return (
    // <div className="uk-section" style={{margin: 0, passing: 0}}>
    <div style={{ position: "relative" }}>
      <MapContainer
        className="uk-section head-map"
        style={{ height: "469.333px" }}
        center={[40, -110]}
        zoom={5}
        scrollWheelZoom={true}
      >
        <TileLayer
          // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={URL}
        />
        {airportsArray ? (
          airportsArray.map((airport) => (
            <Marker
              icon={svgIcon}
              position={[airport.latitude, airport.longitude]}
            >
              <Popup>
                <p>
                  <strong>ICAO:</strong> {airport.icao}
                  <br></br>
                  <strong>Name:</strong> {airport.name}
                </p>
              </Popup>
            </Marker>
          ))
        ) : (
          <></>
        )}
        {sadAirports ? (
          sadAirports.map((airport) => (
            <Marker
              icon={svgIcon2}
              position={[airport.latitude, airport.longitude]}
            >
              <Popup>
                <p>
                  <strong>ICAO:</strong> {airport.icao}
                  <br></br>
                  <strong>Name:</strong> {airport.name}
                </p>
              </Popup>
            </Marker>
          ))
        ) : (
          <></>
        )}
        {routes ? (
          routes.map((route, id) => (
            <Polyline
              key={id}
              positions={[
                [route.dep_lat, route.dep_lon],
                [route.arr_lat, route.arr_lon],
              ]}
              color={"#1e87f0"}
              weight={"2"}
            />
          ))
        ) : (
          <></>
        )}
      </MapContainer>
      <div
        className="uk-section"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          zIndex: 999,
          background: "rgba(0,0,0,.45)",
          pointerEvents: "none",
          height: "469.333px",
        }}
      >
        <div className="uk-container">
          <div className="uk-grid" uk-grid="true">
            <div className="uk-margin-remove-topt@m uk-margin-remove-bottom@m uk-width-1-3@l uk-width-1-2@xl uk-text-center uk-text-left@l uk-text-left@xl uk-first-column">
              <div className="uk-inline uk-width-xlarge">
                <h1 className="uk-h6 uk-margin-remove uk-light">{subTitle}</h1>
                <h1 className="uk-h1 uk-margin-top uk-margin-medium-bottom uk-light">
                  {title}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};
